
import Vue from "vue";
import Post from "@/components/Posts/Post.vue";
import Comments from "@/components/Comments/Comments.vue";
/*import RecommendedMentors from "@/components/Mentor/RecommendedMentors.vue";*/
import { mapGetters } from "vuex";
import ProfileStatistics from "@/components/Profile/ProfileStatistics.vue";
import ProfileActions from "@/components/Profile/ProfileActions.vue";
import ProfileSocial from "@/components/Profile/ProfileSocial.vue";
import ReportUserModal from "@/components/Modals/ReportUserModal.vue";
import ProfileInfo from "@/components/Profile/ProfileInfo.vue";

export default Vue.extend({
  name: "PostDetailsPage",
  components: {
    ProfileInfo,
    ReportUserModal,
    ProfileSocial,
    Post,
    ProfileStatistics,
    ProfileActions,
    Comments
    /*RecommendedMentors*/
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile",
      post: "post/getPost"
    })
  },
  mounted() {
    this.getPost();
  },
  methods: {
    getPost(): void {
      this.$store.dispatch("post/GET_POST", this.$route.params.id);
    },
    updateLike(post_id: any, counts: any) {
      this.post.like_counts = counts;
    },
    profileLink(data: any) {
      if (data.type == "mentee") {
        return `/mentorees/${data.uuid}`;
      }
      if (data.type == "mentor") {
        return `/mentors/${data.uuid}`;
      }
    },
    refresh() {
      this.getPost();

      if ((this as any).$refs["ProfileStatistics"]) {
        (this as any).$refs["ProfileStatistics"]
          .getFollowers()(this as any)
          .$refs["ProfileStatistics"].getFollowings();
      }
    }
  }
});
